import template from './user-table-data.html'

class UserTableDataVM
{
	constructor(page)
	{
		this.page = page;
		this.is_new = ko.observable(true);

		this.table_schema = ko.observable('ut');
		this.table_name = ko.observable('');

		this.fields = ko.observableArray([]);
		this.records = ko.observableArray([]);
	}

	async update ()
	{
		let response = await fetch(`/api/record?schema=${this.table_schema()}&table=${this.table_name()}`);
		let data = await response.json();
		console.log('data=',data);
		this.records(data.records);
	}

	async btnCreateNew_click()
	{
		let obj = {};
		for (let field of this.fields())
		{
			let element = document.querySelector(`input[class="utd-input-field"][data-field="${field.name}"][data-record-id="-1"]`);
			if (!element)
				throw new Error('Element not found');
			obj[field.name] = element.value;
		}

		let response = await fetch('/api/record', {
			method: 'POST',
			headers: {'Content-type': 'application/json'},
			body: JSON.stringify({
				schema: this.table_schema(),
				table: this.table_name(),
				values: obj
			})
		});
		let data = await response.json();
		if (response.ok)
		{
			console.log('create record data=',data);
			this.update();
			this.btnClearNew_click();
		}
		else
		{
			// TODO show error
		}
	}

	async btnDelete_click(record)
	{
	}

	async btnUpdate_click(record)
	{
	}

	async btnView_click(record)
	{
	}

	async btnClearNew_click()
	{
		for (let field of this.fields())
		{
			let element = document.querySelector(`input[class="utd-input-field"][data-field="${field.name}"][data-record-id="-1"]`);
			if (!element)
				throw new Error('Element not found');
			element.value = '';
		}
	}
}

class UserTableDataPage
{
	constructor(bindings)
	{
		this.viewModel = new UserTableDataVM(this);
		if (bindings.table_schema && bindings.table_name)
		{
			this.viewModel.table_schema(bindings.table_schema);
			this.viewModel.table_name(bindings.table_name);

			localStorage.setItem('ut.tableschema', bindings.table_schema);
			localStorage.setItem('ut.tablename', bindings.table_name);
		}
		else if (localStorage.getItem('ut.tableschema') && localStorage.getItem('ut.tablename'))
		{
			this.viewModel.table_schema(localStorage.getItem('ut.tableschema'));
			this.viewModel.table_name(localStorage.getItem('ut.tablename'));
		}
	}

	async init () {
		document.title = 'View Table';

		let response = await fetch(`/api/user-table/${this.viewModel.table_schema()}/${this.viewModel.table_name()}`);
		let data = await response.json();
		console.log('table info data=',data);

		let fields = [];
		for (let field of data.fields)
		{
			field.input = ko.observable('');
			fields.push(field);
		}

		this.viewModel.fields(fields);
	}

	updateData ()
	{
		return this.viewModel.update();
	}
}

export default {
	route: '[/]user-tables/data/:table_schema/:table_name',
	page_class: UserTableDataPage,
	template: template

};

