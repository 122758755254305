import template from './user-table-view.html'

class UserTableViewVM
{
	constructor(page)
	{
		this.page = page;
		this.is_new = ko.observable(true);

		this.table_schema = ko.observable('ut');
		this.table_name = ko.observable('');

		this.fields = ko.observableArray([]);
	}

	buildBS3Table()
	{
		let cols = [];
		for (let field of this.fields())
		{
			cols.push({
				field: field.name,
				title: field.name
			});
		}

		this.tbl_table = $("#tbl_table").bootstrapTable({
			columns: cols,

			dataField: 'records',

			pagination: true,
			pageList: [20, 50, 100],
			pageSize: 20,
			sidePagination: 'server',
			onPageChange: (number, size) => {
				this.updateData();
			},

			onSort: (sortName, sortOrder) => {
				this.updateData();
			},

			search: true,
			searchOnEnterKey: true,
			onSearch: (text) => {
				this.updateData();
			}
		});
	}

	async btnCreateRecord_click()
	{
		let obj = {};
		for (let field of this.fields())
		{
			obj[field.name] = field.input();
		}

		console.log('create record:',obj);
		let response = await fetch('/api/record', {
			method: 'POST',
			headers: {'Content-type': 'application/json'},
			body: JSON.stringify({
				schema: this.table_schema(),
				table: this.table_name(),
				values: obj
			})
		});
		//TODO check response.ok
		let data = await response.json();
		console.log('create recprd data=',data);
		this.update();
	}

	async update ()
	{
		let response = await fetch(`/api/record?schema=${this.table_schema()}&table=${this.table_name()}`);
		let data = await response.json();
		console.log('data=',data);
		$('#tbl_table').bootstrapTable('load', data);
	}

}

class UserTableViewPage
{
	constructor(bindings)
	{
		this.viewModel = new UserTableViewVM(this);
		if (bindings.table_schema && bindings.table_name)
		{
			this.viewModel.table_schema(bindings.table_schema);
			this.viewModel.table_name(bindings.table_name);
		}
	}

	async init () {
		document.title = 'View Table';

		let response = await fetch(`/api/user-table/${this.viewModel.table_schema()}/${this.viewModel.table_name()}`);
		let data = await response.json();
		console.log('table info data=',data);

		let fields = [];
		for (let field of data.fields)
		{
			field.input = ko.observable('');
			fields.push(field);
		}

		this.viewModel.fields(fields);

		this.viewModel.buildBS3Table();
	}


	updateData ()
	{
		return this.viewModel.update();
	}

}

export default {
	route: '[/]user-tables/view/:table_schema/:table_name',
	page_class: UserTableViewPage,
	template: template

};

