
import template from './template.html';

/**
 * This is an example knockout component class. Note the export section at the end of the file.
 * @kind component
 * @class SampleComponent
 * @description Sample Component that shows the name value
 */
class ComponentViewModel {
	constructor(params) {
		this.params = params;

		this.field = ko_helper.safe_observable(params.field || '');
		this.oper = ko_helper.safe_observable(params.oper || '');
		this.value = ko_helper.safe_observable(params.value || '');

		this.remove = params.remove; // Click handler, not observable
	}

	remove_filter() {
		if (this.remove && this.remove instanceof Function)
			this.remove();
	}
}

export default {
	name: 'ko-user-table-filter-field',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};
