import template from './list.html'

class UserTablesListVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
	}
	btnAdd_click()
	{
		Grape.navigate('/user-tables/edit');
	}
}

//Handle Interaction with Page
class UserTablesListPage
{
	constructor(bindings)
	{
		this.viewModel = new UserTablesListVM(this);
		this.access_roles = [];
	}

	async init () {
		document.title = 'UserTables list';

		const actions_formatter = (value, row, index) => {
			return `
			<a class="data" title="Data" href="javascript:void(0)">
				<i class="fa fa-list" style="color: #77C4D3"></i>
			</a>
			<a class="edit" title="Edit" href="javascript:void(0)">
				<i class="fa fa-pencil" style="color: #77C4D3"></i>
			</a>
			<a class="view" title="View" href="javascript:void(0)">
				<i class="fa fa-eye" style="color: #77C4D3"></i>
			</a>
			`;
		};
		
		const actions_events = {
			'click .edit': (e, value, row, index) => {
				console.log('view table click row=',row);
				Grape.navigate('/user-tables/edit', {table_schema: row.user_table_schema, table_name: row.user_table_name});
			},
			'click .view': (e, value, row, index) => {
				console.log('view table click row=',row);
				Grape.navigate('/user-tables/view', {table_schema: row.user_table_schema, table_name: row.user_table_name});
			},
			'click .data': (e, value, row, index) => {
				Grape.navigate('/user-tables/data', {table_schema: row.user_table_schema, table_name: row.user_table_name});
			}

		};



		//COMPONENT: Bootstrap Table
		this.tbl_table = $("#tbl_table").bootstrapTable({
			columns: [
				{field: 'user_table_name', title: 'Name', sortable: true},
				{field: 'estimate', title: 'Row count (estimate)'},
				{field: 'tags', title: 'Tags'},
				{field: 'actions', title: 'Edit', formatter: actions_formatter, events: actions_events}
			],

			dataField: 'records',

			//TABLE PROPERTIES: Paging
			pagination: true,
			pageList: [20, 50, 100],
			pageSize: 20,
			sidePagination: 'server',
			onPageChange: (number, size) => {
				this.updateData();
			},

			//TABLE PROPERTIES: Sort
			// sortOrder: 'asc',
			// sortName: 'user_id',
			onSort: (sortName, sortOrder) => {
				this.updateData();
			},

			//TABLE PROPERTIES: Search
			search: true,
			searchOnEnterKey: true,
			onSearch: (text) => {
				this.updateData();
			}
		});
	}

	async updateData ()
	{

		let response = await fetch('/api/user-table');
		let data = await response.json();
		console.log('data=',data);
		$('#tbl_table').bootstrapTable('load', {
			records: data.records,
			total: data.records?.length || 0
		});

		/*
		let options = {
			table: 'v_access_roles',
			schema: 'grape',
			offset: 0,
			limit: 20,
			sortfield: 'role_name',
			sortorder: 'ASC'
		};

		let table_options = $("#tbl_table").bootstrapTable('getOptions');

		if (table_options.sortName && table_options.sortName != '')
		{
			options.sortfield = table_options.sortName;
			options.sortorder = table_options.sortOrder.toUpperCase();
		}

		if (table_options.searchText && table_options.searchText != "")
		{
			options.filter = [
				{field: 'role_name', operand: 'ILIKE', value: table_options.searchText + '%'}
			];
		}

		if (table_options.pageNumber && table_options.pageSize)
		{
			options.limit = table_options.pageSize;
			options.offset = (table_options.pageNumber - 1) * options.limit;
		}

		//SERVER: api call to grape to retrieve data from db regarding request params set above
		let result = await Grape.tables.select(options);

		$('#tbl_table').bootstrapTable('load', result.result ? result.result : result);
		*/
	}
	
}

export default {
	route: '[/]user-tables/list',
	page_class: UserTablesListPage,
	template: template

};

