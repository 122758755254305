
// PAGES 
import list from './list.js';
import user_table_edit from './user-table-edit.js';
import user_table_view from './user-table-view.js';
import user_table_data from './user-table-data.js';

export default [
	list,
	user_table_edit,
	user_table_view,
	user_table_data
];

