import template from './user-table-edit.html'

class UserTableEditVM
{
	constructor(page)
	{
		this.page = page;
		this.is_new = ko.observable(true);

		this.table_schema = ko.observable('ut');
		this.table_name = ko.observable('');

		this.available_types = ko.observableArray([
			{name: 'TEXT'},
			{name: 'INTEGER'},
			{name: 'DATE'},
			{name: 'TIMESTAMPTZ'},
			{name: 'INTERVAL'}
		]); // TODO fetch from API

		this.new_column_name = ko.observable('');
		this.new_column_type = ko.observable('');

		this.fields = ko.observableArray([]);

		this.available_default_methods = ko.observableArray([
			{title: 'None', value: 'none'},
			{title: 'Copy from existing column', value: 'column'},
			{title: 'Copy from another table', value: 'other table'}
		]);
	}

	async loadExisting()
	{
		let response = await fetch(`/api/user-table/${this.table_schema()}/${this.table_name()}`);
		let data = await response.json();
		console.log('table info data=',data);

		let fields = [];
		for (let field of data.fields)
		{
			field.col_indicator = ko.observable('');
			field.default_value = '';
			fields.push(field);
		}
		console.log('fields:',fields);

		this.fields(fields);
	}

	btnAddColumn_click()
	{
		if (this.new_column_name() == '')
			return;
		if (this.new_column_type() == '')
			return;

		let new_column = {
			name: this.new_column_name(),
			type: this.new_column_type(),
			col_indicator: ko.observable('NEW'),
			default_value: ''
		};
		this.fields.push(new_column);
		this.new_column_name('');
		this.new_column_type('');
	}

	btnDeleteColumn_click(field)
	{
		if (field.col_indicator() === 'NEW')
		{
			let fields = [];
			for (let f of this.fields())
				if (field.name != f.name)
					fields.push(f);
			this.fields(fields);
		}
		else if (field.col_indicator() === 'REMOVE')
		{
			field.col_indicator('');
		}
		else
		{
			field.col_indicator('REMOVE');
		}
	}

	async btnSave_click()
	{
		if (this.is_new() === true)
		{
			let fields = [];
			
			let body = {
				table_schema: this.table_schema(),
				table_name: this.table_name(),
				fields: this.fields()
			};
			let response = await fetch('/api/user-table/create', {method: 'POST', body: JSON.stringify(body), headers: {'Content-type': 'application/json'}});
			let data = await response.json();
			console.log(data);

			Grape.alert({
				type: 'success',
				title: 'Success',
				message: 'Table created'});
		}
		else
		{
			console.log('not new');
			let changes = [];

			let idx = 0;
			for (let field of this.fields())
			{
				if (field.col_indicator() === '')
					continue;
				idx++;
				let change = {idx: idx, field: {name: field.name, type: field.type}};
				if (field.col_indicator() === 'NEW')
					change.action = 'add';
				else if (field.col_indicator() === 'REMOVE')
					change.action = 'remove';
				changes.push(change);
			}
			
			let body = {
				table_schema: this.table_schema(),
				table_name: this.table_name(),
				changes: changes
			};
			let response = await fetch('/api/user-table/alter', {method: 'POST', body: JSON.stringify(body), headers: {'Content-type': 'application/json'}});
			let data = await response.json();
			console.log(data);

			Grape.alert({
				type: 'success',
				title: 'Success',
				message: 'Table altered'});
		}
	}
}

class UserTableEditPage
{
	constructor(bindings)
	{
		this.viewModel = new UserTableEditVM(this);
		if (bindings.table_schema && bindings.table_name)
		{
			this.viewModel.is_new(false);
			this.viewModel.table_schema(bindings.table_schema);
			this.viewModel.table_name(bindings.table_name);
			this.viewModel.loadExisting();
		}
		else
		{
			this.viewModel.is_new(true);
		}
	}

	async init () {
		document.title = 'Edit';
	}

}

export default {
	route: '[/]user-tables/edit',
	page_class: UserTableEditPage,
	template: template

};

