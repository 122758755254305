
// COMPONENTS
import comp1 from './ko-user-table-data-edit/ko-user-table-data-edit.js';
import comp2 from './ko-user-table-data-view/ko-user-table-data-view.js';
import comp3 from './data-pagination/index.js';
import comp4 from './ko-user-table-filter-field/ko-user-table-filter-field.js';

export default [
	comp1,
	comp2,
	comp3,
	comp4
];
