
/* Application entry-point */

import './css/ko-user-table-filter-field.css';
import './css/user-tables.css';

import pages from './pages/index.js';
import components from './components/index.js';

Grape.utils.register_pages(pages);
Grape.utils.register_components(components);

/* "main" */
document.addEventListener('DOMContentLoaded', () => {
});
